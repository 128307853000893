import * as i0 from '@angular/core';
import { Pipe, NgModule } from '@angular/core';
class TuiFilterPipe {
  transform(items, matcher, ...args) {
    return items.filter(item => matcher(item, ...args));
  }
}
TuiFilterPipe.ɵfac = function TuiFilterPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TuiFilterPipe)();
};
TuiFilterPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "tuiFilter",
  type: TuiFilterPipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFilterPipe, [{
    type: Pipe,
    args: [{
      name: 'tuiFilter'
    }]
  }], null, null);
})();
class TuiFilterPipeModule {}
TuiFilterPipeModule.ɵfac = function TuiFilterPipeModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TuiFilterPipeModule)();
};
TuiFilterPipeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiFilterPipeModule
});
TuiFilterPipeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFilterPipeModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiFilterPipe],
      exports: [TuiFilterPipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiFilterPipe, TuiFilterPipeModule };
